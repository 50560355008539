import Tab from './tab';

declare global {
	interface Window {
		downtimeData: any;
	}
}

export async function initCalendarView() {
	/* Get the calendar main div and set the current year*/
	const calendarBlock = document.getElementById('yearCalendar');
	let currentYear = new Date().getFullYear();
	let currentlyDisplayedYear = currentYear; // Track which year is actually being displayed at a given time.

	const d = new Date();
	let month = d.getMonth();
	if (month < 6) {currentYear -= 1}; // Adjust current year to current Admin Year

	const thisYearLabel = document.querySelector('label[for="this-year"]');
	if (thisYearLabel)
		thisYearLabel.innerHTML =
			'<i class="icon icon-check"></i> Current Year (' + currentYear + '–' + (currentYear + 1).toString().slice(2) + ')';

	const nextYearLabel = document.querySelector('label[for="next-year"]');
	if (nextYearLabel)
		nextYearLabel.innerHTML =
			'<i class="icon icon-check"></i> Next Year (' + (currentYear + 1) + '–' + (currentYear + 2).toString().slice(2) + ')';

	// Securely fetch event data from the JSON file in the public folder
	let events = [];
	try {
		if (!window.downtimeData) return;
		const pageDataUrl = window.downtimeData;
		const response = await fetch(pageDataUrl, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		});
		if (!response.ok) {
			throw new Error('Network response was not ok: ' + response.statusText);
		}
		events = await response.json();
	} catch (error) {
		console.error('Failed to fetch events:', error);
		// Optionally display an error message or default data
		return;
	}

	/* Generate month and days of the calendar */
	function generateMonthDays(year: number, month: number) {
		const startDate = new Date(year, month, 1);
		const endDate = new Date(year, month + 1, 0);

		const daysInMonth = endDate.getDate();
		const startDayOfWeek = startDate.getDay();

		// Prepare an array with placeholders for previous month's days
		let daysArray = new Array(startDayOfWeek).fill(null);
		// Fill current month's days
		daysArray = daysArray.concat(
			[...Array(daysInMonth).keys()].map((i) => i + 1)
		);

		return daysArray;
	}

	/* Mark event as downtime or maintenance */
	function markEvents(monthDays: any[], month: number, year: number) {
		const formattedMonth = month + 1; // JavaScript months are 0-indexed
		monthDays = monthDays.map((day) => {
			if (!day) return { day: '', classes: '', hasEvent: false };

			const currentDate = `${year}-${formattedMonth
				.toString()
				.padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
			let eventClasses = '';
			// let eventDescription = '';
			let eventImpact = [];
			let eventType = '';
			let hasEvent = false;
			let isEventStart = false;
			let isEventEnd = false;

			events.forEach((event) => {
				if (currentDate >= event.startDate && currentDate <= event.endDate) {
					hasEvent = true;
					eventType = event.type;
					// eventDescription = event.description;
					// concat the new impact data into existing
					eventImpact = eventImpact.concat(event.impact);
					eventClasses =
						event.type === 'downtime' ? 'event-downtime' : 'event-maintainance';

					// Determine if this day is the start or end of an event
					if (currentDate === event.startDate) {
						isEventStart = true;
					}
					if (currentDate === event.endDate) {
						isEventEnd = true;
					}
				}
			});

			return {
				day,
				classes: eventClasses,
				// eventDescription,
				eventImpact,
				eventType,
				hasEvent,
				isEventStart,
				isEventEnd,
			};
			//return { day, classes: eventClasses, eventDescription, eventType, hasEvent };
		});

		return monthDays;
	}

	/* Main create calendar functions */
	// Note that order of months is based on an admin year (july 1 thru june 30) instead of calendar year
	function createCalendar(year: number) {
		const months = [
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
		];
		const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

		// Update the year display
		document.getElementById('yearDisplay')!.innerText = year.toString() + "–" + (year + 1).toString().slice(2) + " Administration Year"; 

		let calendarHtml = '<div class="calendar-grid">';

		months.forEach((month, index) => {
			var monthDays = [];

			// Do a bit of math to display as admin years (july 1 thru june 30) instead of calendar years
			if (index >= 6) {
				monthDays = generateMonthDays(year + 1, index - 6);
				monthDays = markEvents(monthDays, index - 6, year + 1);
			} else {
				monthDays = generateMonthDays(year, index + 6);
				monthDays = markEvents(monthDays, index+ 6, year);
			};

			calendarHtml += `
				<div class="calendar-month">
					<h3 class="calendar-month-title">${month} ${index <= 5 ? year: year + 1}</h3>
					<div class="calendar-month-header">
						${daysOfWeek
							.map(
								(day) =>
									`<div class="calendar-day" aria-hidden="true">${day}</div>`
							)
							.join('')}
					</div>
					<div class="calendar-month-body">
						${monthDays
							.map(
								(
									{
										day,
										classes,
										// eventDescription,
										eventImpact,
										eventType,
										hasEvent,
										isEventStart,
										isEventEnd,
									},
									index
								) => {
									let additionalClasses = classes;
									if (isEventStart) {
										const previousDay = monthDays[index - 1];
										if (!previousDay || !previousDay.hasEvent) {
											additionalClasses += ' event-start';
										}
									}
									if (isEventEnd) {
										const nextDay = monthDays[index + 1];
										if (!nextDay || !nextDay.hasEvent) {
											additionalClasses += ' event-end';
										}
									}

									// construct the description markup from the eventImpact data. here is what the data looks like:
									// "impact": [
									//   {
									//     "date": "Thu, Feb 15, 2024  5 p.m. - Sun, Feb 18, 2024  8 a.m.",
									//     "systems": [
									//       "Completion Status",
									//       "Data Entry Interface",
									//       "TDS/TA Interface"
									//     ]
									//   },
									//   {
									//     "date": "Thu, Feb 15, 2024  5 p.m. - Sun, Feb 18, 2024",
									//     "systems": ["TOMS"]
									//   }
									// ]

									// here is what the description should look like:
									// "<p><strong>Impacted Systems</strong><br>Thu, Feb 15, 2024  5 p.m. - Sun, Feb 18, 2024  8 a.m.</p><p><strong>Completion Status<br>Data Entry Interface<br>TDS/TA Interface</strong></p><p>Thu, Feb 15, 2024  5 p.m. - Sun, Feb 18, 2024</p><p><strong>TOMS</strong></p>",

									let eventDescription = '';
									if (eventImpact) {
										eventDescription = `<strong>Impacted Systems</strong>`;
										eventDescription += eventImpact
											.map((impact) => {
												return `<p><span class="date">${
													impact.date
												}</span></p><p><strong>${impact.systems.join(
													'<br>'
												)}</strong></p>`;
											})
											.join('<hr style="margin-bottom: 12px;"/>');
									}
									const isMobile = window.innerWidth < 768;

									let hoverTitle = ""

									if (hasEvent) {
										if (eventType == "downtime") {
											hoverTitle = "Select for impacted systems";
										} else { // eventType == "maintenance"
											hoverTitle = eventType.charAt(0).toUpperCase() + eventType.slice(1) + " on " + month + " " + day;
										}
									}

									return hasEvent
										? `<button popovertarget="popover-day-${
												month + day
											}" popovertargetaction="toggle" id="popover-day-${
												month + day
											}-button" class="${additionalClasses} event-day" title="${hoverTitle}" aria-expanded="false" aria-controls="popover-day-${
												month + day
											}">
									<span aria-hidden="true">${day}</span>
									</button>
									<div class="calendar-popup" id="popover-day-${
										month + day
									}" popover role="note" ${isMobile ? '' : ''}>
									<button class="close" popovertarget="popover-day-${
										month + day
									}" popovertargetaction="hide" aria-label="Close"><i class="icon-close"></i></button>
											<div class="calendar-popup-header">
													<i class="${
														eventType === 'downtime'
															? 'icon icon-clock'
															: 'icon icon-wrench'
													}"></i>
													${
														eventType === 'downtime'
															? 'System Downtime'
															: 'Maintenance Window'
													}
											</div>
											<div class="calendar-popup-body">${eventDescription}</div>
									</div>
									`
										: `<div class="${classes} event-day" aria-hidden="true">${day}</div>`;
								}
							)
							.join('')}
					</div>
				</div>
			`;
		});

		calendarHtml += '</div>';
		const calendarBlock = document.querySelector('#yearCalendar');
		if (calendarBlock) {
			calendarBlock.innerHTML = calendarHtml;
			// Post-render adjustment
			// document.querySelectorAll('.event-day').forEach((day) => {
			//   adjustPopupPosition(day);
			// });
		}

		setupKeyboardAccess();
	}

	/* Handle the click and tabbing events */
	function setupKeyboardAccess() {
		const eventDays = document.querySelectorAll('.event-day');
		const isMobile = window.innerWidth < 768;

		eventDays.forEach((day) => {
			// Handle keyboard 'Enter' and 'ESC' key
			// day.addEventListener('keydown', function (e: KeyboardEvent) {
			//   if (e.key === 'Enter') {
			//     e.preventDefault();
			//     // Check if the current popup is already active
			//     if (this.classList.contains('active')) {
			//       this.classList.remove('active'); // Close this popup if it's active
			//     } else {
			//       closeAllPopups(); // Close all other popups
			//       this.classList.add('active'); // Open this popup
			//     }
			//   } else if (e.key === 'Escape' || e.key === 'Esc') {
			//     e.preventDefault();
			//     this.classList.remove('active'); // Close this popup
			//   }
			// });
			// Handle mouse click event
			// day.addEventListener('click', function (e: MouseEvent) {
			//   e.preventDefault();
			//   if (this.classList.contains('active')) {
			//     this.classList.remove('active'); // Close this popup if it's active
			//   } else {
			//     closeAllPopups(); // Close all other popups
			//     this.classList.add('active'); // Open this popup
			//   }
			// });
			// if (!isMobile) {
			//   // Handle mouse hover to toggle popups
			//   day.addEventListener('mouseenter', function (e: MouseEvent) {
			//     closeAllPopupsExcept(this); // Close all other popups, except the current one
			//     this.classList.add('active');
			//   });
			//   // Optionally, handle mouse leave to close the popup
			//   day.addEventListener('mouseleave', function (e: MouseEvent) {
			//     this.classList.remove('active');
			//   });
			// }

			let timeout;
			let hideTimeout;

			const target = day.getAttribute('popovertarget');
			if (target) {
				const popover = document.querySelector('#' + target) as HTMLElement;

				if (!isMobile) {
					// day.addEventListener('mouseenter', () => {
					//   clearTimeout(hideTimeout);
					//   // delay opening
					//   timeout = setTimeout(() => {
					//     popover.showPopover();
					//   }, 300);
					// });

					// day.addEventListener('blur', () => {
					//   clearTimeout(timeout);
					//   hideTimeout = setTimeout(() => {
					//     popover.hidePopover();
					//   }, 300); // 500ms delay
					// });

					popover.addEventListener('toggle', (e: any) => {
						const button = document.getElementById(target + '-button');
						if (e.newState === 'open') {
							const dayRect = day.getBoundingClientRect();
							const popoverRect = popover.getBoundingClientRect();

							const popoverTop = dayRect.top - popoverRect.height - 10;
							const popoverLeft = dayRect.left;

							// Check if popover goes past the right edge of the viewport
							if (popoverLeft + popoverRect.width > window.innerWidth) {
								popover.style.left = `${dayRect.right - popoverRect.width}px`;
							} else {
								popover.style.left = `${popoverLeft}px`;
							}

							// Check if popover goes past the bottom edge of the viewport
							if (popoverTop < 0) {
								popover.style.top = `${dayRect.bottom + 10}px`;
							} else {
								if (isMobile) {
									popover.style.top = `${popoverTop}px`;
								} else {
									popover.style.top = `${popoverTop + window.scrollY}px`;
								}
							}
							if (button) {
								button.setAttribute('aria-expanded', 'true');
							}

							popover.setAttribute('tabindex', '-1');
							popover.setAttribute('aria-live', 'assertive');
							popover.focus();
						} else {
							popover.style.top = '';
							popover.style.left = '';
							// find the button and set aria expanded to false

							if (button) {
								button.setAttribute('aria-expanded', 'false');
							}
						}
					});

					popover.addEventListener('blur', (e: any) => {
						if (
							e.relatedTarget &&
							!popover.contains(e.relatedTarget) &&
							e.relatedTarget !== day
						) {
							popover.hidePopover();
						}
					});

					// day.addEventListener('mouseleave', () => {
					//   // Clear any existing timeout to prevent multiple
					//   clearTimeout(hideTimeout);

					//   // Set a timeout to delay the hiding of the popover
					//   hideTimeout = setTimeout(() => {
					//     popover.hidePopover();
					//   }, 300); // 500ms delay
					// });
				}
			}
		});
	}

	/* Close all the popups */
	function closeAllPopups() {
		const activePopups = document.querySelectorAll('.event-day.active');
		activePopups.forEach((popup) => {
			popup.classList.remove('active');
		});
	}

	/* Close all the popup excent current */
	function closeAllPopupsExcept(currentElement: HTMLElement) {
		const activePopups = document.querySelectorAll('.event-day.active');
		activePopups.forEach((popup) => {
			if (popup !== currentElement) {
				popup.classList.remove('active');
			}
		});
	}

	/* Adjust the popup position */
	function adjustPopupPosition(element) {
		const popup = element.querySelector('.calendar-popup');
		if (!popup) return;

		// Start aligned to the left relative to the event-day
		popup.style.left = '0';
		popup.style.right = 'auto';

		const popupRect = popup.getBoundingClientRect();
		const container = document.getElementById('yearCalendar');
		const containerRect = container.getBoundingClientRect();

		// Check if the popup goes out of the bounds of the container on the right side
		if (popupRect.right > containerRect.right) {
			const overflowRight = popupRect.right - containerRect.right;
			popup.style.left = `auto`;
			popup.style.right = `0`;
		}
	}

	/* Run the calendar */
	if (calendarBlock) {
		/* Update the calender to the previous year */
		// document.getElementById('prevYear')?.addEventListener('click', (e) => {
		//   e.preventDefault(); // Prevent default action that might cause page jumps
		//   currentYear -= 1;
		//   createCalendar(currentYear);
		// });

		/* Update the calender to next year */
		let nextYearValue = currentYear + 1;
		document.getElementById('next-year')?.addEventListener('change', (e) => {
			// e.preventDefault(); // Prevent default action
			currentlyDisplayedYear = nextYearValue;
			createCalendar(nextYearValue);
		});

		nextYearLabel?.addEventListener('keypress', (e: any) => {
			if (e.key !== 'Enter') return;
			document.getElementById('next-year')?.click();
		});

		/* Set the calendar to the current */
		document.getElementById('this-year')?.addEventListener('change', (e) => {
			// e.preventDefault(); // Prevent default action
			currentYear = new Date().getFullYear(); // Reset the year to current
			currentlyDisplayedYear = currentYear;
			createCalendar(currentYear);
		});

		thisYearLabel?.addEventListener('keypress', (e: any) => {
			if (e.key !== 'Enter') return;
			document.getElementById('this-year')?.click();
		});
	}

	/* Adjust the Popup positon on Year Tab Nav button click */
	/* This is because by default its hidden inside the tab content */
	var tabElement = document.querySelector('#yearView-tab');
	if (tabElement) {
		tabElement.addEventListener('shown.bs.tab', function (event) {
			// Ensure the calendar is visible

			/* Create the calendar on Year Tab Show event */
			createCalendar(currentlyDisplayedYear);
		});
	}

	/* Get all the tab items */
	var tabItems = document.querySelectorAll('[data-bs-toggle="tab"]');
	tabItems.forEach((tab) => {
		tab.addEventListener('click', function () {
			// Properly create a new URL object from the current location's href
			const url = new URL(window.location.href); // Use .href to get a string
			url.searchParams.set('activeTab', this.id);
			window.history.pushState({ tab: this.id }, '', url.toString()); // Ensure to convert URL object to string
			activateTab(this.id);
		});
	});

	/* Activate the tab ID from the query parameter URL */
	if (document.readyState === 'loading') {
		// Loading hasn't finished yet
		document.addEventListener('DOMContentLoaded', () => {
			const params = new URLSearchParams(window.location.search);
			const activeTabId = params.get('activeTab');
			activateTab(activeTabId);
		});
	} else {
		// `DOMContentLoaded` has already fired
		const params = new URLSearchParams(window.location.search);
		const activeTabId = params.get('activeTab');
		activateTab(activeTabId);
	}

	/* Activate tab function */
	function activateTab(tabId) {
		const activeTab = document.getElementById(tabId);
		if (activeTab) {
			const tab = new Tab(activeTab);
			tab.show();

			if (tabId === 'yearView-tab' && calendarBlock) {
				// Programmatically click the "Today Year" button to reset the calendar
				const todayYearBtn = document.getElementById('todayYear');
				if (todayYearBtn) {
					todayYearBtn.click(); // This triggers any event listener attached to this button
				}
			}
		} else {
			// Optionally activate a default tab if no specific tab is requested
			const defaultTab = document.querySelector('[data-bs-toggle="tab"]');
			if (defaultTab) {
				const defaultTabInstance = new Tab(defaultTab);
				defaultTabInstance.show();
			}
		}
	}
}
