export function initSearchFilter() {
  const noResultsHTML = `<div class="card-search card-search-empty" id="form-no-results" style="display: none;">
  <div class="card-search-icon">
    <img
      src="/assets/img/icon-search-empty.svg"
      alt="Search empty icon"
    />
  </div>
  <p class="card-search-text">
    No results found. Please try refining your search.
  </p>
</div>`;

  // if the no results div is not present inside #filter-contents, add it
  if (
    !document.querySelector('#form-no-results') &&
    document.querySelector('#filter-contents')
  ) {
    document
      .querySelector('#filter-contents')
      .insertAdjacentHTML('beforeend', noResultsHTML);
  }

  const searchInput = document.querySelector(
    '#search-filter-input'
  ) as HTMLInputElement;
  const filterResultsCount = document.querySelector('#filter-results-count');
  const formIntro = document.querySelector('#form-filter-intro');
  const formNoResults = document.querySelector('#form-no-results');
  const filterByBlock = document.querySelector(
    '#filter-by-block'
  ) as HTMLInputElement;
  const filterResetBtn = document.querySelector('#filter-reset-btn');
  const searchClearBtn = document.querySelector(
    '#filter-clear-btn'
  ) as HTMLInputElement;
  const formCheckInputs = document.querySelectorAll(
    '.form-check-input[type="checkbox"]'
  ) as NodeListOf<HTMLInputElement>;
  let tableRows = document.querySelectorAll(
    '#filter-contents tbody tr:not(.ignore)'
  ) as NodeListOf<HTMLTableRowElement>;
  let tableFluidDivs = document.querySelectorAll('.table-fluid');
	// Handles edge cases where cells contain anchor tags, but you don't want them searched
	let searchTextOnly = document.querySelectorAll('.search-text-only')[0];

  // Page: Form Assignments for the 2023-24 Summative ELPAC Administration
  const formGroups = document.querySelectorAll(
    '[data-form-group]'
  ) as NodeListOf<HTMLElement>;
  const formCheckGroups = document.querySelectorAll(
    '.form-check-group'
  ) as NodeListOf<HTMLInputElement>;

  // Page: Upcoming and On-Demand Trainings
  let filterTrainingsDivs = document.querySelectorAll(
    '.filter-trainings'
  ) as NodeListOf<HTMLDivElement>;
  let trainingsAccordionItems = document.querySelectorAll(
    '.filter-trainings .accordion-item'
  ) as NodeListOf<HTMLDivElement>;

  // Page: Search Results
  let searchResultsDivs = document.querySelectorAll(
    '.card-search:not(.card-search-empty)'
  ) as NodeListOf<HTMLDivElement>;

  const filterForm = document.querySelector('#filter-form');
  const filterByToggle = document.querySelector('#filter-by-toggle');

  let searchFilteredRows = new Set(tableRows);
  let categoryFilteredRows = new Set(tableRows);

  let categoryFilteredTrainings = new Set(trainingsAccordionItems);

  setNoResultsVisibility(false);

  // Create a new observer instance
  const observer = new MutationObserver((mutationsList, observer) => {
    // Handle the mutations
    mutationsList.forEach((mutation) => {
      // Check if the mutation is an addition or removal of a child node
      if (
        mutation.type === 'childList' &&
        (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0)
      ) {
        // Handle the changes in the markup inside #filter-contents
        // TODO: Add your code here
        // Get the new tableRows
        const newTableRows = document.querySelectorAll(
          '#filter-contents tbody tr:not(.ignore)'
        ) as NodeListOf<HTMLTableRowElement>;

        // Check if the number of tableRows has changed
        if (newTableRows.length !== tableRows.length) {
          // Update the tableRows and the related sets
          tableRows = newTableRows;
          searchFilteredRows = new Set(tableRows);
          categoryFilteredRows = new Set(tableRows);
        }

        const newFilterTrainingsDivs = document.querySelectorAll(
          '.filter-trainings'
        ) as NodeListOf<HTMLDivElement>;
        const newTrainingsAccordionItems = document.querySelectorAll(
          '.filter-trainings .accordion-item'
        ) as NodeListOf<HTMLDivElement>;

        if (newFilterTrainingsDivs.length !== filterTrainingsDivs.length) {
          filterTrainingsDivs = newFilterTrainingsDivs;
          trainingsAccordionItems = newTrainingsAccordionItems;
          categoryFilteredTrainings = new Set(trainingsAccordionItems);
        }

        const newSearchResultsDivs = document.querySelectorAll(
          '.card-search:not(.card-search-empty)'
        ) as NodeListOf<HTMLDivElement>;

        if (newSearchResultsDivs.length !== searchResultsDivs.length) {
          searchResultsDivs = newSearchResultsDivs;
        }
      }
    });
  });

  if (document.querySelector('#filter-contents') && observer) {
    observer.observe(document.querySelector('#filter-contents'), {
      childList: true,
      subtree: true,
    });
  }

  // Function to manage visibility of .table-fluid and form intro
  function toggleVisibility(hasResults) {
    tableFluidDivs.forEach((div) => {
      if (hasResults) {
        div.classList.remove('hidden');
        if (formIntro) {
          (formIntro as HTMLElement).style.display = 'none';
        }
      } else {
        div.classList.add('hidden');
        if (formIntro) {
          (formIntro as HTMLElement).style.display = '';
        }
      }
    });
  }

  // Function to hide the form block
  function hideFormBlocks() {
    if (formGroups) {
      formGroups.forEach((group) => {
        group.classList.add('hidden');
        group.classList.remove('noresult');
      });
    }
    // if (formIntro) {
    //   (formIntro as HTMLElement).style.display = '';
    // }
  }

  /* TRIGGER: Prevent the form submit */
  //filterForm.addEventListener('submit', handleForm);

  /* Apply filter function */
  function applyFilters() {
    // Combine the search and category filters
    if (tableRows.length) {
      const combinedFilteredRows = new Set(
        [...searchFilteredRows].filter((row) => categoryFilteredRows.has(row))
      );

      // Apply combined visibility
      tableRows.forEach((row) => {
        row.style.display = combinedFilteredRows.has(row) ? '' : 'none';
        (row as HTMLElement).style.display = combinedFilteredRows.has(row)
          ? ''
          : 'none';
      });

      // Update the count display
      const visibleCount = combinedFilteredRows.size;
      // if (filterResultsCount) {
      //   console.log('filterResultsCount', visibleCount);
      //   filterResultsCount.textContent = `${visibleCount} Result${
      //     visibleCount !== 1 ? 's' : ''
      //   }`;
      // }
      // if no search term or filters are applied, update the results count to zero
      const anyFormInputFiltersApplied = Array.from(formCheckInputs).some(
        (input) => input.checked
      );
      if (searchInput.value.trim() === '' && !anyFormInputFiltersApplied) {
        updateResultsCount(0);
      } else {
        updateResultsCount(visibleCount);
      }

      // Hide intro and show/hide table based on visible rows
      if (formIntro && tableFluidDivs) {
        toggleVisibility(
          visibleCount > 0 || searchInput.value.trim().length > 0
        );
      }
    }
  }

  /* Search results update function */
  function updateSearchResults() {
    let emptyResults = true;
    if (!searchInput) return;
    const searchTerm = searchInput.value.toLowerCase();
    searchFilteredRows.clear(); // Reset for new search
    setNoResultsVisibility(false);
    const anyFormFiltersApplied = Array.from(formCheckGroups).some(
      (input) => input.checked
    );
    const anyFormInputFiltersApplied = Array.from(formCheckInputs).some(
      (input) => input.checked
    );
    const anyFiltersApplied =
      anyFormFiltersApplied || anyFormInputFiltersApplied;
    // if the search term is empty, and there are no filters selected, show the default state
    if (searchTerm === '') {
      searchClearBtn.style.display = 'none';
    } else {
      searchClearBtn.style.display = 'block';
    }

    // trainings page
    if (filterTrainingsDivs.length && trainingsAccordionItems.length) {
      const anyFiltersApplied = Array.from(formCheckInputs).some(
        (input) => input.checked
      );

      const selectedCategories = Array.from(formCheckInputs)
        .filter((input) => input.checked)
        .map((input) => input.value);

      let totalResults = 0;
      if (searchTerm === '' && !anyFiltersApplied) {
        filterTrainingsDivs.forEach((trainingsDiv) => {
          trainingsDiv.classList.remove('noresult');
          const accordionItems =
            trainingsDiv.querySelectorAll('.accordion-item');
          accordionItems.forEach((item) => {
            item.classList.remove('noresult');
            totalResults++;
          });
        });

        const currentTab = document.querySelector('.nav-tabs .active');

        // then add up only the visible items on that tab
        if (currentTab) {
          const currentTabId = currentTab.getAttribute('data-bs-target');
          const currentTabItems = document.querySelectorAll(
            `${currentTabId} .accordion-item`
          );
          totalResults = Array.from(currentTabItems).filter(
            (item) => !item.classList.contains('noresult')
          ).length;

          updateResultsCount(totalResults);
        }
        return;
      }

      filterTrainingsDivs.forEach((trainingsDiv) => {
        let groupHasMatch = false;
        const accordionItems = trainingsDiv.querySelectorAll('.accordion-item');
        accordionItems.forEach((item) => {
          item.classList.remove('noresult');
          const itemCategories = (item as HTMLElement).dataset.category
            ? (item as HTMLElement).dataset.category.split(',')
            : [];

          const itemHasAllSelectedCategories = selectedCategories.every(
            (category) => itemCategories.includes(category.toLowerCase())
          );

          if (
            (searchTerm === '' ||
              item.textContent.toLowerCase().includes(searchTerm)) &&
            (selectedCategories.length === 0 || itemHasAllSelectedCategories)
          ) {
            groupHasMatch = true;
            totalResults++;
          } else {
            item.classList.add('noresult');
          }
        });

        if (!groupHasMatch) {
          trainingsDiv.classList.add('noresult');
        } else {
          trainingsDiv.classList.remove('noresult');
        }
      });

      // check the current tab, #upcomingTrainings-tab or #allTrainings-tab
      const currentTab = document.querySelector('.nav-tabs .active');

      // then add up only the visible items on that tab
      if (currentTab) {
        const currentTabId = currentTab.getAttribute('data-bs-target');
        const currentTabItems = document.querySelectorAll(
          `${currentTabId} .accordion-item`
        );
        totalResults = Array.from(currentTabItems).filter(
          (item) => !item.classList.contains('noresult')
        ).length;

        updateResultsCount(totalResults);
      }
    }

    // If table row is not empty
    if (tableRows.length != 0) {
      if (searchTerm === '' && !anyFiltersApplied) {
        if (formIntro) {
          (formIntro as HTMLElement).style.display = '';
        }
        // toggleVisibility(false);

        // show all rows
        tableRows.forEach((row) => {
          row.style.display = '';
          (row as HTMLElement).style.display = '';
        });
        updateResultsCount(0);
      }

      tableRows.forEach((row) => {
        // Try to find the td with data-searchable="true"
        const searchableCell = Array.from(row.cells).find(
          (cell: Element) => cell.getAttribute('data-searchable') === 'true'
        );
        // Use the searchable cell if found, otherwise fallback to the second cell
        const subjectCell = searchableCell ? searchableCell : row.cells[1];
        //const subjectCell = row.cells[1];
				let subjectText;
				if(searchTextOnly) {
					subjectText = getTextWithoutClass(subjectCell, "ignore").toLowerCase();
					// subjectText = subjectCell.textContent.toLowerCase();
				} else {
					subjectText = subjectCell.querySelector('a')
					? subjectCell.querySelector('a').textContent.toLowerCase()
					: subjectCell.textContent.toLowerCase();
				}

        if (searchTerm === '' || subjectText.includes(searchTerm)) {
          searchFilteredRows.add(row);

          searchClearBtn.style.display = 'block';

          if (searchTerm === '') {
            removeHighlight(row); // Ensure highlights are removed when search is cleared
            emptyResults = true;
            searchClearBtn.style.display = 'none';
          } else {
            highlightSearchTerm(row, searchTerm); // Apply highlights for non-empty search terms
            emptyResults = false;
          }
        } else {
          removeHighlight(row); // Remove highlights from rows that do not match the current search
        }
      });
    }

    // Handle each form group individually
    if (formGroups.length != 0) {
      if (searchTerm === '' && !anyFiltersApplied) {
        hideFormBlocks();
        if (formIntro) {
          (formIntro as HTMLElement).style.display = '';
        }
        updateResultsCount();
        return;
      }
      (formIntro as HTMLElement).style.display = 'none';
      formGroups.forEach((group) => {
        // group.classList.remove('hidden'); // Ensure all form groups are visible when search starts

        let groupHasMatch = false; // Assume no matches within this group initially
        const rows = group.querySelectorAll('tbody tr:not(.ignore)'); // Get all rows within this form group

        // get the checkbox for this group
        const groupCheckbox = Array.from(formCheckGroups).find(
          (input) => input.value === group.dataset.formGroup
        );

        if (groupCheckbox) {
          if (anyFiltersApplied) {
            if (groupCheckbox.checked) {
              // If checked, show the group
              group.classList.remove('hidden');
            } else {
              // If not checked, hide the group

              group.classList.add('hidden');
            }
          } else {
            // If no filters are applied, show all groups
            group.classList.remove('hidden');
          }
          // Check if the checkbox for this group is checked
        }

        rows.forEach((row) => {
          const searchableCell = Array.from(
            (row as HTMLTableRowElement).cells
          ).find(
            (cell: Element) => cell.getAttribute('data-searchable') === 'true'
          );
          const subjectCell = searchableCell
            ? searchableCell
            : (row as HTMLTableRowElement).cells[1];
          // const searchableCell = Array.from(row.cells).find((cell: Element) => cell.getAttribute('data-searchable') === 'true');
          // const subjectCell = searchableCell ? searchableCell : row.cells[1];
          const subjectText = subjectCell.querySelector('a')
            ? subjectCell.querySelector('a').textContent.toLowerCase()
            : subjectCell.textContent.toLowerCase();

          if (subjectText.includes(searchTerm)) {
            groupHasMatch = true; // Found at least one match in this group

            if (searchTerm === '') {
              // hideFormBlocks(); // Hide all form blocks
              // (formIntro as HTMLElement).style.display = ''; // Show intro block
            }
          } else {
            // groupHasMatch = false;
            //(formIntro as HTMLElement).style.display = '';
          }
        });

        // Apply .noresult class based on match status
        if (!groupHasMatch) {
          group.classList.add('noresult');
        } else {
          group.classList.remove('noresult');
        }
      });
    } else {
      //console.log("dont match criteria");
    }

    if (searchResultsDivs.length !== 0) {
      // search results only feature the category filtering
      let totalResults = 0;
      searchResultsDivs.forEach((result) => {
        let groupHasMatch = false;
        const itemCategories = result.dataset.category
          ? result.dataset.category.split(',')
          : [];

        const selectedCategories = Array.from(formCheckInputs)
          .filter((input) => input.checked)
          .map((input) => input.value);

        const itemHasAllSelectedCategories = selectedCategories.every(
          (category) => itemCategories.includes(category.toLowerCase())
        );

        if (
          Array.from(formCheckInputs).length === 0 ||
          itemHasAllSelectedCategories
        ) {
          groupHasMatch = true;
        } else {
          result.classList.add('noresult');
        }

        if (!groupHasMatch) {
          result.classList.add('noresult');
        } else {
          result.classList.remove('noresult');
          totalResults++;
        }
      });
      updateResultsCount(totalResults);
    }

    applyFilters(); // Combine and apply filters

    // Check if the search term is empty
    if (emptyResults && searchTerm !== '') {
      // Remove filter results
      // removeFilterResults();
      // When no searchTerm is present, ensure correct visibility state is set.
      toggleVisibility(false);
      if (formIntro) {
        (formIntro as HTMLElement).style.display = 'none';
      }
    } else {
      // Show the table fluid divs
      toggleVisibility(true);
    }

		// Hide rows that should be hidden by default
		if (emptyResults && searchTextOnly && filterResultsCount.textContent == "") { 
			toggleVisibility(false);
			if (formIntro) {
				(formIntro as HTMLElement).style.display = '';
			}
		}
  }

  /* Update Counter Function */
  function updateFilterButtonCounter() {
    const filterActionButton = document.querySelector('.filter-action-btn');
    //let checkedCount = Array.from(formCheckGroups).filter(input => input.checked).length;
    const checkedCount =
      Array.from(formCheckInputs).filter((input) => input.checked).length +
      Array.from(formCheckGroups).filter((input) => input.checked).length;
    let countSpan;

    // Update or remove the counter span based on the number of checked checkboxes
    if (checkedCount > 0) {
      if (filterActionButton) {
        countSpan = filterActionButton.querySelector('.filter-action-count');
      }
      if (countSpan) {
        countSpan.textContent = checkedCount.toString(); // Convert number to string
        // console.log("if count span exists");
      } else {
        // Add new count span if it doesn't exist
        const newCountSpan = document.createElement('span');
        newCountSpan.className = 'filter-action-count';
        newCountSpan.textContent = checkedCount.toString(); // Convert number to string
        filterActionButton.prepend(newCountSpan);

        // Hide Intro Block
        if (formIntro) {
          (formIntro as HTMLElement).style.display = 'none'; // Show intro block
        }
      }
    } else {
      // Remove the count span if no checkboxes are checked
      if (filterActionButton) {
        countSpan = filterActionButton.querySelector('.filter-action-count');
      }
      if (countSpan) {
        countSpan.remove();
      }

      // Remove filter results
      removeFilterResults();

      // Hide forms blocks
      // hideFormBlocks();
    }

    if (filterActionButton) {
      // Always ensure "Filter" text is present
      if (!filterActionButton.textContent.includes('Filter')) {
        filterActionButton.appendChild(document.createTextNode(' Filter'));
      }
    }
  }

  /* Show/Hide Block Function - Filter by block */
  function toggleFilterByBlock() {
    const isBlockVisible = filterByBlock.style.display !== 'none';
    filterByBlock.style.display = isBlockVisible ? 'block' : 'none';
  }

  /* Remove Filter Results */
  function removeFilterResults() {
    // Reset search count values
    if (filterResultsCount) {
      filterResultsCount.textContent = '';
    }
  }

  /* Filter by Category Functions - Checkboxes Fields */
  function filterByCategory() {
    categoryFilteredRows.clear(); // Reset for new category filter
    const selectedCategories = Array.from(formCheckInputs)
      .filter((input) => input.checked)
      .map((input) => input.value);

    tableRows.forEach((row) => {
      const rowCategories = row.dataset.categories
        ? row.dataset.categories.split(',')
        : [];
      if (
        selectedCategories.length === 0 ||
        selectedCategories.some((category) => rowCategories.includes(category))
      ) {
        categoryFilteredRows.add(row);
      }
    });

    applyFilters(); // Combine and apply filters

    // Filter checkboxes counter
    updateFilterButtonCounter();
  }

  /* Function to update visibility of form groups based on checkbox filters */
  function filterByFormGroups() {
    const checkedForms = Array.from(formCheckGroups)
      .filter((input) => input.checked)
      .map((input) => input.value);

    // Toggle visibility of form groups based on checked values
    formGroups.forEach((group) => {
      if (
        checkedForms.length === 0 ||
        checkedForms.includes(group.dataset.formGroup)
      ) {
        group.classList.remove('hidden');
      } else {
        group.classList.add('hidden');
      }
    });

    applyFilters();

    // Update the results count based on visible form groups
    updateResultsCount();

    // Filter checkboxes counter
    updateFilterButtonCounter();
  }

  /* Function to update the results count displayed */
  function updateResultsCount(num?: number) {
    let visibleRowsCount = 0;
    if (formGroups.length > 0) {
      formGroups.forEach((group) => {
        if (!group.classList.contains('hidden')) {
          const rows = group.querySelectorAll('tbody tr:not(.ignore)');
          rows.forEach((row) => {
            if ((row as HTMLElement).style.display !== 'none') {
              visibleRowsCount++;
            }
          });
        }
      });
    } else {
      if (num) visibleRowsCount = num;
    }

    if (filterResultsCount) {
      const anyFormFiltersApplied = Array.from(formCheckGroups).some(
        (input) => input.checked
      );
      const anyFormInputFiltersApplied = Array.from(formCheckInputs).some(
        (input) => input.checked
      );
      const anyFiltersApplied =
        anyFormFiltersApplied || anyFormInputFiltersApplied;

      if (
        visibleRowsCount === 0 &&
        searchInput.value.trim() === '' &&
        !anyFiltersApplied
      ) {
        filterResultsCount.textContent = '';
      } else {
        filterResultsCount.textContent = `${visibleRowsCount} Result${
          visibleRowsCount !== 1 ? 's' : ''
        }`;
      }

      if (
        visibleRowsCount === 0 &&
        (anyFiltersApplied || searchInput.value.trim() !== '')
      ) {
        hideFormBlocks();
        setNoResultsVisibility(true);
      } else {
        setNoResultsVisibility(false);
      }
    }
  }

  function setNoResultsVisibility(visibility: boolean) {
    if (formNoResults) {
      (formNoResults as HTMLElement).style.display = visibility ? '' : 'none';
    }
  }

  /* Reset Filters Functions */
  function resetFilters() {
    // Reset the search input field
    // searchInput.value = '';

    // Uncheck all checkbox filters
    formCheckInputs.forEach((input) => (input.checked = false));
    formCheckGroups.forEach((input) => (input.checked = false));

    // Reset the filtered rows states to include all rows
    searchFilteredRows = new Set(tableRows);
    categoryFilteredRows = new Set(tableRows);

    // Remove highlights from all rows
    tableRows.forEach((row) => {
      removeHighlight(row);
    });

    // Remove highlights and noresult class from all rows and groups
    document.querySelectorAll('.form-group').forEach((group) => {
      group.classList.remove('noresult'); // Ensure noresult class is removed on reset
    });

    // Reapply filters to update the UI correctly
    applyFilters(); // This will now reset the display based on the cleared filters and search

    // Remove filter results
    removeFilterResults();

    // Filter checkboxes counter
    updateFilterButtonCounter();
  }

  /* Remove the highlight styles form the searched keyword */
  function removeHighlight(row) {
    // Try to find the td with data-searchable="true"
    const searchableCell = Array.from(row.cells).find(
      (cell: Element) => cell.getAttribute('data-searchable') === 'true'
    );
    // Use the searchable cell if found, otherwise fallback to the second cell
    const cell = searchableCell ? searchableCell : row.cells[1];
    //const cell = row.cells[1];
    const links = cell.querySelectorAll('a');
    if (links.length) {
      links.forEach((link) => {
        // Explicitly remove <span> tags used for highlighting
        const spanTags = link.querySelectorAll('span');
        spanTags.forEach((span) => {
          span.outerHTML = span.innerHTML; // Replace each span with its inner content
        });
      });
    } else {
      // For cells without <a> tags, this will effectively remove any highlights
      const spanTags = cell.querySelectorAll('span');
      spanTags.forEach((span) => {
        span.outerHTML = span.innerHTML; // Replace each span with its inner content
      });
    }
  }

  /* Highlight the search keyword in the table data text */
  function highlightSearchTerm(row, term) {
    if (!term.trim()) return; // Skip if search term is empty

    // Try to find the td with data-searchable="true"
    const searchableCell = Array.from(row.cells).find(
      (cell: Element) => cell.getAttribute('data-searchable') === 'true'
    );
    // Use the searchable cell if found, otherwise fallback to the second cell
    const cell = searchableCell ? searchableCell : row.cells[1];
    //const cell = row.cells[1]; // Assuming the subject is in the second column
    const regex = new RegExp(`(${term})`, 'gi');

    // Directly target the text within <a> tags, if present
    const links = cell.querySelectorAll('a');
    if (links.length) {
      links.forEach((link) => {
        link.innerHTML = link.textContent.replace(
          regex,
          `<span style="background-color: #1059A1; color: #ffffff;">$1</span>`
        );
      });
    } else {
      // Fallback for cells without <a> tags
      // cell.innerHTML = cell.textContent.replace(regex, `<span style="background-color: #1059A1; color: #ffffff;">$1</span>`);
    }
  }

  // Function to handle both filter reset and clear actions
  function handleClearAndResetFilter() {
    resetFilters(); // Reset all filters

    // Hide the clear button
    // searchClearBtn.style.display = 'none';

    // Show the intro block
    // if (formIntro) {
    //   (formIntro as HTMLElement).style.display = ''; // Show intro
    // }

    // Hide form blocks
    // hideFormBlocks();

    // Show/Hide Filter intro and Table Fluid Div
    // if (formIntro && tableFluidDivs) {
    //   toggleVisibility(false);
    // }
    updateSearchResults();
  }

  /* TRIGGER: Search filter input */
  if (searchInput) {
    let timeoutId;
    searchInput.addEventListener('input', () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (searchInput.value.trim() === '') {
          // Hide form blocks
          // hideFormBlocks();
        } else {
          searchClearBtn.style.display = 'block'; // Show the clear button
          // Ensure all form groups are visible when user starts typing
          // formGroups.forEach((group) => {
          //   group.classList.remove('hidden');
          // });
        }

        const tableRowsLength = tableRows.length;
        let searchInputLengthRequirement = 3;
        if (tableRowsLength > 0 && tableRowsLength <= 100) {
          searchInputLengthRequirement = 1;
        }
        if (
          searchInput.value.trim().length >= searchInputLengthRequirement ||
          searchInput.value.trim() === ''
        ) {
          updateSearchResults();
        }
      }, 750);
    });

    if (searchInput.form?.action !== '/search') {
      searchInput.form?.addEventListener('submit', (e) => {
        e.preventDefault();
      });

      searchInput.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      });
    }
  }

  /* TRIGGER: Show/hide filter by checkbox option */
  if (filterByToggle) {
    filterByToggle.addEventListener('click', (e) => {
      e.preventDefault(); // Prevent the default anchor action
      toggleFilterByBlock();
    });
  }

  /* TRIGGER: Filter Reset */
  if (filterResetBtn) {
    filterResetBtn.addEventListener('click', (e) => {
      e.preventDefault(); // Prevent the default anchor action
      handleClearAndResetFilter();
    });
  }

  /* TRIGGER: Filter Clear */
  if (searchClearBtn) {
    // prevent keydown from triggering the form submit
    // searchClearBtn.addEventListener('keydown', (e) => {
    //   if (e.key === 'Enter') {
    //     e.preventDefault(); // Prevent the default anchor action
    //   }
    // });
    searchClearBtn.addEventListener('click', (e) => {
      e.preventDefault(); // Prevent the default anchor action
      searchInput.value = ''; // Clear the search input field
      // trigger the change event
      searchInput.dispatchEvent(new Event('input'));
    });
  }

  /* TRIGGER: Filter by category checkboxes */
  if (formCheckInputs.length) {
    formCheckInputs.forEach((input) =>
      input.addEventListener('change', () => {
        filterByCategory();
        updateSearchResults();
      })
    );
  }

  /* TRIGGER: Filter by form group checkboxes */
  if (formCheckGroups.length) {
    formCheckGroups.forEach((input) =>
      input.addEventListener('change', () => {
        // filterByFormGroups();
        updateSearchResults();
      })
    );
  }

  const trainingsPageNavTabs = document.querySelectorAll(
    '#upcomingTrainings-tab, #allTrainings-tab'
  ) as NodeListOf<HTMLAnchorElement>;
  // when a nav tab is clicked, updateSearchResults()
  if (trainingsPageNavTabs.length) {
    trainingsPageNavTabs.forEach((tab) =>
      tab.addEventListener('click', () => {
        updateSearchResults();
      })
    );
  }
}

/* TOGGLE BLOCK */
export function toggleBlock(target, targetSelector) {
  // Find the target element using the provided selector
  const targetSelectorDiv = document.querySelector(targetSelector);

  // Toggle the .hidden class on the target element
  if (target) {
    target.classList.toggle('active');
  }
  if (targetSelectorDiv) {
    targetSelectorDiv.classList.toggle('hidden');
  }
}

function getTextWithoutClass(cell, className) {
	let text = "";

	// Create a temporary element to hold the cell's content
	const tempDiv = document.createElement("div");
	tempDiv.innerHTML = cell.innerHTML;

	// Remove elements with the specified class
	const elementsToRemove = tempDiv.querySelectorAll("." + className);
	elementsToRemove.forEach(element => element.remove());

	// Get the remaining text content
	text = tempDiv.textContent;

	return text;
}
